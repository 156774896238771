import React, { useState, useRef, useEffect } from 'react';
import { MdCancelPresentation } from 'react-icons/md';
import { FaWhatsapp } from "react-icons/fa";

const EnrollmentForm = ({ isOpen, onClose }) => {
    
  const overlayRef = useRef();
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    gender: '',
    email: '',
    guardian_name: '',
    guardian_number: '',
    college: '',
    qualification: '',
    passout_year: '',
    city: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      const handleOverlayClick = (e) => {
        if (overlayRef.current && e.target === overlayRef.current) {
          onClose();
        }
      };
      document.addEventListener('click', handleOverlayClick);
      return () => {
        document.removeEventListener('click', handleOverlayClick);
      };
    }
  }, [isOpen, onClose]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://www.api.CodeMonc.com';

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      const response = await fetch(`${apiEndpoint}/api/enroll/enrollstudent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Fetch Response:', result);

        setFormData({
          name: '',
          number: '',
          gender: '',
          email: '',
          guardian_name: '',
          guardian_number: '',
          college: '',
          qualification: '',
          passout_year: '',
          city: '',
        });

        setIsSubmitted(true);
        setTimeout(() => {
          setIsSubmitted(false);
          onClose();
        }, 30000);
      } else {
        console.error('Fetch Error:', response.status);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    const requiredFields = [
      'name',
      'number',
      'gender',
      'email',
      'guardian_name',
      'guardian_number',
      'college',
      'qualification',
      'passout_year',
      'city',
    ];

    requiredFields.forEach((field) => {
      if (!data[field].trim()) {
        errors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    if (data.number.length !== 10 || !/^\d+$/.test(data.number)) {
      errors.number = 'Number must be 10 digits and contain only digits';
    }

    if (data.guardian_number.length !== 10 || !/^\d+$/.test(data.guardian_number)) {
      errors.guardian_number = 'Guardian Number must be 10 digits and contain only digits';
    }

    return errors;
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99999999999]" ref={overlayRef}>
      <div className="bg-white p-1 md:p-6 rounded-md w-full sm:w-[80%] lg:w-[50%] text-center ">
        {isSubmitted ? (
          <div className="text-center relative">
            <img src="./media/qr.jpg" alt="QR Code" className="w-52 md:w-[70%] - left-0 right-0 mx-auto" />
            <p className='text-sm '>Scan This QR Code to pay the fees and share payment receipt at Whatsapp by clicking below button </p>
            <button className="action-button bg-[#25D366] text-white  mx-auto"><a href="https://api.whatsapp.com/send?phone=918800763839" className='flex gap-2'><p>Share receipt</p><FaWhatsapp size="24" className='' /></a>
            </button>
          </div>
        ) : (
          <>
            <span className=" absolute right-1 lg:right-80  -mt-1 lg:-mt-5  z-[9999] " onClick={onClose}>
              <MdCancelPresentation size={30} />
            </span>
            <h1 className="text-center  text-blue font-bold text-xl pb-2">Enroll Now</h1>
            <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-1 h-96  w-full">
              <div className=''>
                {errors.name && <p className="text-red text-xs">{errors.name}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.name && 'border-red-500'
                  }`}
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.number && <p className="text-red text-xs">{errors.number}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm dfocus:outline-none focus:border-blue-500 ${
                    errors.number && 'border-red-500'
                  }`}
                  type="number"
                  placeholder="Your Number"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.gender && <p className="text-red text-xs">{errors.gender}</p>}
                <div>
                <select
                  className={`border-b-2 border-darkgreen -ml-10  rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.gender && 'border-red-500'
                  }`}
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                </div>
              </div>
              <div>
                {errors.email && <p className="text-red text-xs">{errors.email}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.email && 'border-red-500'
                  }`}
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.guardian_name && <p className="text-red text-xs">{errors.guardian_name}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.guardian_name && 'border-red-500'
                  }`}
                  type="text"
                  placeholder="Guardian Name"
                  name="guardian_name"
                  value={formData.guardian_name}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.guardian_number && <p className="text-red text-xs">{errors.guardian_number}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.guardian_number && 'border-red-500'
                  }`}
                  type="number"
                  placeholder="Guardian Number"
                  name="guardian_number"
                  value={formData.guardian_number}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.college && <p className="text-red text-xs">{errors.college}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.college && 'border-red-500'
                  }`}
                  type="text"
                  placeholder="College Name"
                  name="college"
                  value={formData.college}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.qualification && <p className="text-red text-xs">{errors.qualification}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.qualification && 'border-red-500'
                  }`}
                  type="text"
                  placeholder="Qualification"
                  name="qualification"
                  value={formData.qualification}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.passout_year && <p className="text-red text-xs">{errors.passout_year}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.passout_year && 'border-red-500'
                  }`}
                  type="text"
                  placeholder="Passout Year"
                  name="passout_year"
                  value={formData.passout_year}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.city && <p className="text-red text-xs">{errors.city}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.city && 'border-red-500'
                  }`}
                  type="text"
                  placeholder="City Name"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <button className="bg-blue col-span-2 text-white py-2 px-4 rounded-sm hover:bg-blue-700 transition w-52 m-auto" type="submit">
                Submit
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
export default EnrollmentForm;