import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Typewriter from "typewriter-effect";
import PostCard from '../../components/PostCard';
import CallToAction from '../../components/CallToAction';

import './Blog.css'
const Blog = () => {
  const [posts, setPosts] = useState([]);
  const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://www.api.CodeMonc.com';
 


  useEffect(() => {
    const fetchPosts = async () => {
      const res = await fetch(`${apiEndpoint}/api/post/getPosts`);
      const data = await res.json();
      setPosts(data.posts);
    };
    fetchPosts();
  }, []);
  return (
    <>
    
    <div className='h-52 w-full relative '>
        <div className="border h-full w-full absolute">
            <img src='./media/39.jpg' alt='CodeMonc Coding School' className='w-full h-full object-cover'></img>
        </div>
        <div className='absolute flex items-center justify-center w-full h-full bg-black/50'>
        <h1 className="text-xl md:text-4xl text-white font-bold tracking-wider  r">
            <Typewriter 
              options={{
                        strings: ['Check Our Latest Blogs...'],
                        autoStart: true,
                        loop: true,
                      }}>
            </Typewriter>
        </h1>
        </div>
    </div>

    <div>
      <div className='flex flex-col gap-6 p-5 px-3 max-w-6xl mx-auto text-center '>
        <h1 className='text-3xl font-semibold lg:text-6xl text-darkgreen'>Welcome to Our Blogs</h1>
        <p className='text-gray-500 text-xs sm:text-sm'>
          Here you'll find a variety of articles and tutorials on topics such as
          web development, software engineering, and programming languages.
        </p>
        <Link
          to='/search'
          className='text-xs sm:text-sm text-darkgreen font-bold hover:underline'
        >
          View all posts
        </Link>
      </div>
      <div className='p-5 '>
        <CallToAction />
      </div>

      <div className='max-w-6xl mx-auto p-3 flex flex-col gap-8 py-7'>
        {posts && posts.length > 0 && (
          <div className='flex flex-col gap-6'>
            <h2 className='text-2xl font-semibold text-center'>Recent Posts</h2>
            <div className='flex flex-wrap gap-4  justify-center'>
              {posts.map((post) => (
                <PostCard key={post._id} post={post} />
              ))}
            </div>
            <Link
              to={'/search'}
              className='text-lg text-teal-500 hover:underline text-center'
            >
              View all posts
            </Link>
          </div>
        )}
      </div>
    </div>
 
    </>
  )
}

export default Blog
