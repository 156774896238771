import { Button } from "flowbite-react"
import { AiFillGoogleCircle } from "react-icons/ai"
import { GoogleAuthProvider, signInWithPopup,getAuth } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import {  useDispatch } from "react-redux"
import { signInSuccess } from "../redux/user/UserSlice"
import {app} from '../Firebase'

export default function OAuth(){
    const auth = getAuth(app)
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://api.CodeMonc.com';
  
    const handleGoogleClick=async ()=>{
        const provider=new GoogleAuthProvider()
        provider.setCustomParameters({prompt:'select_account'})
        try{
            const resultsFromGoogle=await signInWithPopup(auth,provider)
            const res=await fetch(`${apiEndpoint}/api/auth/google`,{
                method:'POST',
                headers:{'Content-Type':'application/json'},
                body:JSON.stringify({
                    name:resultsFromGoogle.user.displayName,
                    email:resultsFromGoogle.user.email,
                    googlePhotoUrl:resultsFromGoogle.user.photoURL
                })
            })
            const data=await res.json()
            if(res.ok){
                dispatch(signInSuccess(data))
                navigate('/')
            }
        }catch(error){
            console.log(error)
        }
    }
    return(
        <>
        <div>
            <Button className="bg-red w-full" onClick={handleGoogleClick}>
                <AiFillGoogleCircle className="w-6 h-6 pr-1"/> 
                <p>Continue with Google</p>
            </Button>
        </div>
        </>
    )
}