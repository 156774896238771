import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import jsPDF from 'jspdf';
import axios from 'axios';


const ScholarshipResult = () => {
  const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://api.CodeMonc.com';
  



  return (
    <>
    <div className='py-16' id='result'>
    <h1 className='text-darkblue font-bold text-xl md:text-3xl tracking-wider text-center mt-5 py-2 md:py-2 '>
        Scholarship <span className='text-blue'><img src='./media/img21.png' className='w-20 md:w-36 z-[9999] absolute  left-[52%] -mt-1 ' alt='no img'></img>Results</span>
      </h1>
      <p className='text-center p-3 text-xl text-gray-600 my-5'>Warm wishes to the winners of Scholarship test organized by <span className='text-blue font-semibold'>CodeMonc</span> at <span className='text-blue font-semibold'>Shri Venkateshwara University</span></p>
      <div className='flex flex-wrap justify-center   gap-5 md:gap-12   px-10 mx-auto'>
        <div className='flex flex-col gap-5 text-center'>
            <h1 className='text-xl font-semibold text-darkgreen'>1<sup>st</sup>:Nadeem</h1>
            <h1 className='text-xl font-semibold text-darkgreen'>2<sup>nd</sup>:Nishant</h1>
            <h1 className='text-xl font-semibold text-darkgreen'>3<sup>rd</sup>:Munendra</h1>
        
        </div>
        {/* <div className='h-48 w-48   text-center'>
        </div>
        <div className='h-48 w-48   text-center'>
        </div> */}
      </div>
      <p className='text-center p-3 text-lg text-gray-600 '>If you want to know in detail about Scholarship test and it's result , you can proceed with any of below options </p>
      <div className=' mx-auto flex flex-wrap justify-center gap-5'>
  
      
          <a href="https://drive.google.com/uc?export=download&id=1MxDHi-7umbrd-k2VO2ZENibLWl7Cs0Xe
        " download="Mewar_scholarship_result.pdf">
          <button className='bg-darkgreen text-white p-3 rounded-md' >Download Result Report</button>
            </a>

        <a href='https://drive.google.com/uc?export=download&id=1sbPll5b18o19kejaKoH4mVQiiD6F5JMe'>
            <button className='bg-darkgreen text-white p-3 rounded-md' >View Result only</button>
        </a>
      
        <a href="https://api.whatsapp.com/send?phone=918800763839&text=Hello....">
            <button className='bg-darkgreen text-white p-3 rounded-md'>Check on Whatsapp</button>
        </a>
      </div>
    </div>
    </>
  )
}
export default ScholarshipResult
