import React, { useState } from 'react';
import { UisStar, UisStarHalfAlt } from '@iconscout/react-unicons-solid';
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import '../../App.css'
const Courses = () => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate=useNavigate()
  const [isFlipped, setFlipped] = useState(false)
  const [isFlipped2, setFlipped2] = useState(false);
  const [isFlipped3, setFlipped3] = useState(false);
  const [isFlipped4, setFlipped4] = useState(false);

  const handleFlip = () => {
      setFlipped(!isFlipped);
  };
  const handleFlip2 = () => {
    setFlipped2(!isFlipped2);
  };
  const handleFlip3 = () => {
    setFlipped3(!isFlipped3);
  };
  const handleFlip4 = () => {
    setFlipped4(!isFlipped4);
  };
  const downloadMernCourse=()=>{
    try {
      if (!currentUser) {
        navigate('/sign-in');
        return;
      }
     
  }catch(err){
    console.log(err.message)
   }
  }
  return (
    <>
    <h1 className='text-darkblue font-bold text-xl md:text-3xl tracking-wider text-center m-5 py-2 md:py-2 '>
        What we <span className='text-blue'><img src='./media/img21.png' className='w-20 md:w-32 z-[9999] absolute  left-[50%] -mt-1 ' alt='CodeMonc Coding School'></img>Offer</span>
      </h1>
      {/* Card for Courses-------------------------------- */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-6 px-5 md:px-16 lg:px-32 py-4">
                <div
                    className={`flip-card ${
                        isFlipped ? "flipped" : ""
                    } shadow-lg h-36 `}
                >
                    <div className="flip-card-inner">
                        <div className="flip-card-front grid grid-cols-3">
                              <div className='bg-blue text-white flex flex-col justify-between p-2'>
                              <h1 className='font-semibold text-sm md:text-md' >Full Stack Web Development</h1>
                              <div className='flex'>
                                <UisStar/><UisStar/><UisStar/><UisStar/><UisStarHalfAlt/>
                              </div>
                              </div>
                              <div className='p-2 col-span-2 flex flex-col justify-between'>
                                <p className='text-black text-xs md:text-sm font-thick tracking-widest'>6 MONTH PROGRAM</p>
                                <p className='font-bold tracking-wider text-sm lg:text-xl'>MERN STACK</p>
                                <p className='text-black text-[11px] md:text-sm font-thick tracking-widest'>Tap to know more about this course</p>
                                <div className='flex gap-5'>
                                <button
                                    className="flip-button bg-gray-200 p-1 w-24"
                                    onClick={handleFlip}
                                >
                                    See More
                                </button>

                                <button
                                className="flip-button bg-blue text-white p-1"
                                onClick={downloadMernCourse}
                             
                            >
                                {currentUser ? (
                                    <a href="https://drive.google.com/uc?export=download&id=1Gs75aXHoyMAWqRTonDmwriapo8FTd5Qg" download="mern-course">
                                        Download Course
                                    </a>
                                ) : (
                                    <span>Download Course</span>
                                )}
                            </button>
                                </div>
                              
                              </div>
                        </div>
                        <div className="flip-card-back flex flex-col justify-between py-2 px-5">
                            <div className="card-content">
                              <p className='text-xs leading-4 tracking-wide text-center '>
                              MERN is an acronym that stands for MongoDB, Express.js, React, and Node.js. It represents a stack of technologies commonly used together to build web applications.Join Our Course today and be <span className='text-yellow'>Job ready within 6 months</span>
                              </p>    
                            </div>
                            <button
                                className="flip-button bg-gray-200 p-1 w-full"
                                onClick={handleFlip}
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`flip-card ${
                        isFlipped2 ? "flipped" : ""
                    } shadow-lg h-36`}
                >
                    <div className="flip-card-inner">
                        <div className="flip-card-front grid grid-cols-3">
                              <div className='bg-yellow text-white flex flex-col justify-between p-2'>
                              <h1 className='font-semibold text-sm md:text-md' >Cloud Computing</h1>
                              <div className='flex'>
                                <UisStar/><UisStar/><UisStar/><UisStar/><UisStarHalfAlt/>
                              </div>
                              </div>
                              <div className='p-2 col-span-2 flex flex-col justify-between'>
                                <p className='text-black text-xs md:text-sm font-thick tracking-widest'>2 MONTH PROGRAM</p>
                                <p className='font-bold tracking-wider text-sm lg:text-xl'>AWS, GCP, AZURE</p>
                                <p className='text-black text-[11px] md:text-sm font-thick tracking-widest'>Tap to know more about this course</p>
                              <button
                                className="flip-button bg-gray-200 p-1 w-24"
                                onClick={handleFlip2}
                            >
                                See More
                            </button>
                              </div>
                        </div>
                        <div className="flip-card-back flex flex-col justify-between py-2 px-5">
                            <div className="card-content">
                              <p className='text-xs leading-4 tracking-wide text-center '>
                              Cloud computing is a paradigm that involves delivering computing services over the internet instead of relying on physical hardware and infrastructure. Join Our Course to Learn very Demanding  service like AWS ,  Azure, Google Cloud Platform (GCP)
                              </p>    
                            </div>
                            <button
                                className="flip-button bg-gray-200 p-1 w-full"
                                onClick={handleFlip2}
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    className={`flip-card ${
                        isFlipped3 ? "flipped" : ""
                    } shadow-lg h-36 border `}
                >
                    <div className="flip-card-inner">
                        <div className="flip-card-front grid grid-cols-3">
                              <div className='bg-darkgreen text-white flex flex-col justify-between p-2'>
                              <h1 className='font-semibold text-sm md:text-md' >Other Programming Languages</h1>
                              <div className='flex'>
                                <UisStar/><UisStar/><UisStar/><UisStar/><UisStarHalfAlt/>
                              </div>
                              </div>
                              <div className='p-2 col-span-2 flex flex-col justify-between'>
                                <p className='text-black text-xs md:text-sm font-thick tracking-widest'>2 MONTH PROGRAM</p>
                                <p className='font-bold tracking-wider text-sm lg:text-xl'>PYTHON, C++, JAVA</p>
                                <p className='text-black text-[11px] md:text-sm font-thick tracking-widest'>Tap to know more about this course</p>
                              <button
                                className="flip-button bg-gray-200 p-1 w-24"
                                onClick={handleFlip3}
                            >
                                See More
                            </button>
                              </div>
                        </div>
                        <div className="flip-card-back flex flex-col justify-between py-2 px-5">
                            <div className="card-content">
                              <p className='text-xs leading-4 tracking-wide text-center '>
                              Outside from the implications of coding which look good on a resume, coding actually does boost skills which are actually useful to most jobs. Problem solving and logic are the main two. Learning to code is like an exercise session for the “left” side of the brain.Join our course today to learn High Demanding Skills 
                              </p>    
                            </div>
                            <button
                                className="flip-button bg-gray-200 p-1 w-full"
                                onClick={handleFlip3}
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                </div>


                <div
                    className={`flip-card ${
                        isFlipped4 ? "flipped" : ""
                    } shadow-lg h-36 border `}
                >
                    <div className="flip-card-inner">
                        <div className="flip-card-front grid grid-cols-3">
                              <div className='bg-darkblue text-white flex flex-col justify-between p-2'>
                              <h1 className='font-semibold text-sm md:text-md'>DATABASES</h1>
                              <div className='flex'>
                                <UisStar/><UisStar/><UisStar/><UisStar/><UisStarHalfAlt/>
                              </div>
                              </div>
                              <div className='p-2 col-span-2 flex flex-col justify-between'>
                                <p className='text-black text-xs md:text-sm font-thick tracking-widest'>1 MONTH PROGRAM</p>
                                <p className='font-bold tracking-wider text-sm lg:text-xl'>MySQL, MongoDb, Firebase</p>
                                <p className='text-black text-[11px] md:text-sm font-thick tracking-widest'>Tap to know more about this course</p>
                              <button
                                className="flip-button bg-gray-200 p-1 w-24"
                                onClick={handleFlip4}
                            >
                                See More
                            </button>
                              </div>
                        </div>
                        <div className="flip-card-back flex flex-col justify-between py-2 px-5">
                            <div className="card-content">
                              <p className='text-xs leading-4 tracking-wide text-center '>
                              Databases are fundamental to information management and play a crucial role in various fields, including business, technology, and research.Join Us today to learn Databases that use by Top Tech Comapies 
                              
                              </p>    
                            </div>
                            <button
                                className="flip-button bg-gray-200 p-1 w-full"
                                onClick={handleFlip4}
                            >
                                Go Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
};

export default Courses;
