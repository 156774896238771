import {useState,useEffect} from 'react'
import './Header.css'
// import { NavLink } from 'react-router-dom';
import { Avatar,  Button, Dropdown, DropdownDivider, DropdownHeader, DropdownItem, Navbar, TextInput } from 'flowbite-react';

import { AiOutlineSearch } from "react-icons/ai";
import { IoMdMenu,IoMdArrowDropdown } from "react-icons/io";
import { MdCancel ,MdOutlineKeyboardArrowDown} from "react-icons/md";
import { Link, useLocation ,useNavigate} from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';
import { signoutSuccess } from '../../redux/user/UserSlice';
import EnrollmentForm from '../modals/EnrollmentForm';
const Header = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const openModal2 = () => {
    setModalOpen(true);
    setIsMobileMenuOpen(!isMobileMenuOpen)
  };

  const closeModal = () => {
    setModalOpen(false);
  };
 
  const dispatch=useDispatch()
  const path=useLocation().pathname
  const navigate = useNavigate();
  const location = useLocation();
  const {currentUser}=useSelector(state=>state.user)
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMenu=()=>{
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }
  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);
  const toggleFirstDropdown = () => {
    setIsFirstDropdownOpen(!isFirstDropdownOpen);
    // Close the second dropdown when opening the first one
    setIsSecondDropdownOpen(false);
  };
  const toggleSecondDropdown = () => {
    setIsSecondDropdownOpen(!isSecondDropdownOpen);
  };
  const DropdownStyle={backgroundColor:'gray',outline:'none',border:'none'}
  const DropdownStyle2={backgroundColor:'white',outline:'none',border:'none',color:'gray'}
  const[dropdown,setDropdown]=useState(false)
  function openDropdown(){
    setDropdown(!dropdown)
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const searchTermFromUrl = urlParams.get('searchTerm');
    if (searchTermFromUrl) {
      setSearchTerm(searchTermFromUrl);
    }
  }, [location.search]);

  const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://api.CodeMonc.com';

  const handleSignout=async()=>{
    try {
      const res=await fetch(`${apiEndpoint}/api/user/signout`,{
        method:'POST'
      })
      const data=await res.json()
      if(!res.ok){
        console.log(data.message)
      }else{
        dispatch(signoutSuccess())
      }
      
    } catch (error) {
      console.log(error.message)
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('searchTerm', searchTerm);
    const searchQuery = urlParams.toString();
    navigate(`/search?${searchQuery}`);
  };


  return(
    <>
    <nav className=' flex justify-between z-[99999] sticky top-0 bg-blue h-16'>
      <div className='p-2 w-[20%] md:w-[10%]  bg-white  '>
        <Link to='/'>
          <img src='media/clogo10.png' alt='CodeMonc Coding School' className='w-32 scale-95 -mt-2'></img>
        </Link>
      </div>
      <div className='w-[80%]  md:w-[90%] flex gap-2 justify-end p-2 bg-white'>
        <div className='hidden md:flex gap-3 text-center '>
        <Link to="/" 
          className='rounded-md min-w-32 p-2  transition-all duration-300 hover:bg-blue hover:text-white  font-semibold text-darkgreen'
          >Home</Link>
          <Link  to='/blogs'  className='rounded-md min-w-32 p-2   transition-all duration-300 hover:bg-blue hover:text-white  font-semibold text-darkgreen'>Blog</Link> 
        {/* <Link  to='/contact' className='rounded-md min-w-32 p-2  transition-all duration-300 hover:bg-blue hover:text-white  font-semibold text-darkgreen'>Contact</Link> */}
        <a  href='#result' className='rounded-md min-w-32 p-2  transition-all duration-300 hover:bg-blue hover:text-white  font-semibold text-darkgreen'>Results</a> 

        <Link  h className='rounded-md min-w-32 p-2  transition-all duration-300 hover:bg-blue hover:text-white  font-semibold text-darkgreen' onClick={openModal}>Enroll Now</Link>
          <div className=''>
            <button className=' min-w-32 p-2 text-center transition-all duration-300 hover:bg-blue hover:text-white  font-semibold text-darkgreen flex border' onClick={openDropdown}>Courses <MdOutlineKeyboardArrowDown size={22}/></button>  
            <div className={`${dropdown ? 'h-52' : 'h-0'} overflow-hidden flex justify-center flex-col gap-3 bg-white`} >
               <Dropdown label="Web development" style={DropdownStyle2}> 
                      <DropdownItem>
                      <ul
                    id="menu-lang"
                    aria-hidden="true"
                    className="bg-white border rounded-sm 
                  min-w-32  
                  "
                        >
                    <Link to='/courses' onClick={openDropdown}>
                    <li className=' px-2 font-semibold text-center'>MERN Stack </li>               
                    <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">JavaScript</li>
                    <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">React</li>
                    <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">MongoDB</li>
                    <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">NodeJS</li>
                    <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">ExpressJs</li>
                    </Link>
                  </ul>
                      </DropdownItem>
                    </Dropdown>            
                    <Dropdown label="Other Languages" style={DropdownStyle2} >
                      <Link to='/courses' onClick={openDropdown}>
                      <DropdownItem>Python</DropdownItem>
                      <DropdownItem>C++</DropdownItem>
                      <DropdownItem>JavaScript</DropdownItem>
                      </Link>
                    </Dropdown>
                    
                  <Dropdown label="Databases" style={DropdownStyle2} >
                  <Link to='/courses' onClick={openDropdown}>
                      <DropdownItem>SQL</DropdownItem>
                      <DropdownItem>MongoDB</DropdownItem>
                      <DropdownItem>Firebase</DropdownItem>
                      </Link>
                    </Dropdown>
                  <Dropdown label="Cloud Computing" style={DropdownStyle2}>
                  <Link to='/courses' onClick={openDropdown}>
                  < DropdownItem>AWS</DropdownItem>
                  < DropdownItem>GCP</DropdownItem>
                  < DropdownItem>Azure</DropdownItem>
                  </Link>
                  </Dropdown>
              
            </div>
           
          </div>



        </div>
      <form onSubmit={handleSubmit} >
        <TextInput
          type='text'
          placeholder='Search...'
          rightIcon={AiOutlineSearch}
          className='hidden md:inline'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}

        />
      </form>
      <Button className='w-12 h-10 md:hidden' color='gray' pill>
        <AiOutlineSearch  color=''/>
      </Button>
      <div className='flex gap-2 '>
        {/* <Button className='w-12 h-10 hidden sm:inline ' color='gray' pill>
          <FaMoon/>
        </Button> */}
        {currentUser?(
          <Dropdown
          arrowIcon={false}
          inline
          label={
            <Avatar
            alt='user'
            img={currentUser.profilePicture}
            rounded
            />
          }
          >
            <DropdownHeader>
              <span className='text-sm block'>@{currentUser.username}</span>
              <span className='text-sm block truncate'>{currentUser.email}</span>
            </DropdownHeader>
            <Link to={'/dashboard?tab=profile'}>
              <DropdownItem>Profile</DropdownItem>
            </Link>
            <DropdownDivider/>
            <DropdownItem onClick={handleSignout}>Sign out</DropdownItem>

            
          </Dropdown>
        ):(
        <Link to='/sign-in'>
          <Button >Sign In</Button>
        </Link>
        )}
        
        <Button className='w-12 h-10' onClick={toggleMenu} style={{backgroundColor:'#edba10'}}>
          {isMobileMenuOpen? <MdCancel size={30}  />: <IoMdMenu size={30}  />}
        </Button>
        </div> 
      </div>
    </nav>
   

    <div className={`${isMobileMenuOpen?'block h-[100vh]':'hidden h-0'} top-0 flex  bg-blue h-0 w-full flex-col items-center gap-7  text-white duration-1000 ease-in-out fixed z-[99999999999] overflow-auto `}>
        <div className='flex justify-between p-3 w-full bg-white'>
        <Link to='/'>
          <img src='./media/clogo10.png' alt='CodeMonc Coding School' className='w-16 '></img>
        </Link>
        <MdCancel size={30}  onClick={toggleMenu} className='text-darkgreen cursor-pointer ' />
        </div>
            <div className='flex flex-col text-2xl justify-end gap-6 ' >
          <Link to="/" onClick={toggleMenu}
          className='rounded-md min-w-32 transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white'
          >Home</Link>

      {/* for courses */}
      <button className='rounded-md min-w-32 text-center transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white flex' onClick={openDropdown}>Courses <IoMdArrowDropdown/></button>  

      <div className={`${dropdown ? 'h-52' : 'h-0'} overflow-hidden`}>
      <Dropdown label="Web development" style={DropdownStyle}> 
                <DropdownItem>
                <ul
              id="menu-lang"
              aria-hidden="true"
              className="bg-white border rounded-sm 
    min-w-32  
    "
            >
              <Link to='/courses' onClick={toggleMenu}>
              <li className=' px-2 font-semibold text-center'>MERN Stack </li>               
              <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">JavaScript</li>
              <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">React</li>
              <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">MongoDB</li>
              <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">NodeJS</li>
              <li className="px-3 py-1 hover:bg-gray-100 text-sm text-gray-500">ExpressJs</li>
              </Link>
            </ul>
                </DropdownItem>
              </Dropdown>            
              <Dropdown label="Other Languages" style={DropdownStyle}>
              <Link to='/courses' onClick={toggleMenu}>
                <DropdownItem>Python</DropdownItem>
                <DropdownItem>C++</DropdownItem>
                <DropdownItem>JavaScript</DropdownItem>
                </Link>
              </Dropdown>
            <Dropdown label="Databases" style={DropdownStyle}>
            <Link to='/courses' onClick={toggleMenu}>
                <DropdownItem>SQL</DropdownItem>
                <DropdownItem>MongoDB</DropdownItem>
                <DropdownItem>Firebase</DropdownItem>
                </Link>
              </Dropdown>
            <Dropdown label="Cloud Computing" style={{
              backgroundColor:'gray'
              
            }} >
              <Link to='/courses' onClick={toggleMenu}>
            < DropdownItem>AWS</DropdownItem>
            < DropdownItem>GCP</DropdownItem>
            < DropdownItem>Azure</DropdownItem>
            </Link>
            </Dropdown>
        
        </div>
    
        <a  href='#result' className='rounded-md min-w-32 -mt-3 transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white' onClick={toggleMenu} >Results</a>                  
        <Link onClick={toggleMenu} to='/blogs'  className='rounded-md min-w-32 -mt-3 transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white'>Blog</Link>
        <Link onClick={toggleMenu} to='/contact' className='rounded-md min-w-32  transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white'>Contact</Link>
        <Link onClick={toggleMenu} to='/project' className='rounded-md min-w-32  transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white'>Projects</Link>
        <Link onClick={toggleMenu} to='/career' className='rounded-md min-w-32  transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white'>Career</Link>
        <Link  to='/' className='rounded-md min-w-32  transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white' onClick={openModal2} >Enroll Now</Link>
        <Link  to='https://docs.google.com/forms/d/e/1FAIpQLSefZO4andpBglYjvzlPSnmn22JTFc5NwxxDmx54dEHIA4Uu4w/viewform?usp=sf_link ' className='rounded-md min-w-32  transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white' onClick={toggleMenu} >Scholarship</Link>
        
        {/* <Link onClick={toggleMenu} to='https://docs.google.com/forms/d/e/1FAIpQLSefZO4andpBglYjvzlPSnmn22JTFc5NwxxDmx54dEHIA4Uu4w/viewform?usp=sharing' className='rounded-md min-w-32  transition-all duration-300 hover:bg-white hover:text-darkblue  font-semibold text-white'>Scholarship</Link> */}
            </div>
        </div>
        <EnrollmentForm isOpen={isModalOpen} onClose={closeModal}/>
    </>
  )
}
export default Header
