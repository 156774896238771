import React, { useState } from 'react';

const Faq = () => {
  const items = [
    {
      title: 'Who is eligible to apply for this course ?',
      content: 'Anyone keen to learn about web development can enroll with us to learn about this course. '
    },
    {
      title: 'How does CodeMonc provide placement assistance ?',
      content: `CodeMonc provides 100% placement assistance through the following key initiatives:<br><br>

      1. <strong>Industry Connections:</strong> CodeMonc maintains strong ties with leading tech companies, creating a network for potential job placements.<br><br>
      
      2. <strong>Skill Development:</strong> Rigorous training programs ensure students acquire in-demand skills, enhancing their employability.<br><br>
      
      3. <strong>Resume Building:</strong> CodeMonc offers guidance on resume creation and optimization to showcase student's skills and achievements effectively.<br><br>
      
      4. <strong>Mock Interviews:</strong> Conducting mock interviews helps students refine their interview skills and gain confidence in professional settings.<br><br>
      
      5. <strong>Job Portals:</strong> CodeMonc connects students with job portals, providing access to a wide range of job opportunities in the tech industry.<br><br>
      
      6. <strong>Career Counseling:</strong> Personalized career counseling sessions assist students in aligning their skills with industry requirements, optimizing their job search.<br><br>
      
      7. <strong>Internship Opportunities:</strong> Offering paid internship projects, CodeMonc ensures students gain practical experience, enhancing their attractiveness to employers.<br>
      `,
    },
    {
      title: 'Can I Attend a Demo Session before Enrollment ?',
      content: 'You can definitely attend 2 free Demo classes with us. Enrollment is loyalty between you and us, where you promise to be a good learner. ',
    },
    {
      title: 'Is there a mentorship program at CodeMonc ?',
      content: 'Yes we provide Live class mentor , Live project mentor , Internship mentor etc.' ,
    },
    {
      title: 'Are there any additional benefits if I enroll in CodeMonc ?',
      content: 'Yes definitely, along with course CodeMonc also provides life time membership card,you can continue classes,doubt solving sessions, and project workshops even after completion of your course.',
    },
  ];
  
  const [openAccordions, setOpenAccordions] = useState(new Array(items.length).fill(false));

  const handleClick = (index) => {
    setOpenAccordions((prevOpenAccordions) => {
      const newOpenAccordions = [...prevOpenAccordions];
      newOpenAccordions[index] = !newOpenAccordions[index];
      return newOpenAccordions;
    });
  };

  

  return (
    <>
      <h1 className='text-darkblue font-bold text-xl md:text-3xl tracking-wider text-center mt-5 py-2 md:py-2 '>
        Frequently Asked <span className='text-blue'><img src='./media/img21.png' className='w-20 md:w-36 z-[9999] absolute  left-[55%] -mt-1 ' alt='CodeMonc Coding School'></img>Questions</span>
      </h1>
      <div id='faq' className='grid grid-cols-1 md:grid-cols-2 p-5 '>
        <div className='p-2 flex justify-center items-start '>
          <div className='w-52 md:w-1/2 overflow-hidden ' >
            <img id='faqImage' src='./media/img25.jpg ' alt='CodeMonc Coding School found' className='w-full h-full object-contain ' ></img>
          </div>
        </div>
        <div className=" ">
        {items.map((item, index) => (
          <div key={index} id={`faqItem-${index}`} className="mb-1 mx-auto rounded-lg overflow-hidden">
            <div
              className={`flex items-center justify-between p-4 cursor-pointer text-darkgreen shadow-lg ${
                openAccordions[index] ? 'bg-blue text-white' : 'bg-gray-100/80'
              } transition-all duration-500`}
              onClick={() => handleClick(index)}
            >
              <span className="text-lg font-semibold">{item.title}</span>
              <span className="text-xl">{openAccordions[index] ? '-' : '+'}</span>
            </div>
            {openAccordions[index] && (
              <div className="p-4 bg-gray-100  duration-500">
                <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            )}
          </div>
        ))}
      </div>
      </div>
    </>
  );
};

export default Faq;
