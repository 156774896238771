import { Alert, Button, Modal, ModalBody, ModalHeader, TextInput } from 'flowbite-react'
import { useEffect, useRef, useState } from 'react'
import { useSelector ,useDispatch} from 'react-redux'
import { HiOutlineExclamationCircle } from 'react-icons/hi';

import {getDownloadURL,getStorage,ref,uploadBytesResumable} from 'firebase/storage';
import { app } from '../../Firebase.js';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {
  updateStart,
  updateSuccess,
  updateFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  signoutSuccess,
} from '../../redux/user/UserSlice'
import { Link } from 'react-router-dom';

const DashProfile = () => {
 
  
  const {currentUser,error,loading  }=useSelector(state=>state.user)
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);
  const [imageFileUploadProgress, setImageFileUploadProgress] = useState(null);
  const [imageFileUploadError, setImageFileUploadError] = useState(null);
  const [imageFileUploading, setImageFileUploading] = useState(false);
  const [formData,setFormData]=useState({})
  const[updateUserSucess,setUpdateUserSucess]=useState(null)
  const[updateUserError,setUpdateUserError]=useState(null)
  const[showModal,setShowModal]=useState(false)

  const dispatch=useDispatch()
  const filePickerRef = useRef();
 const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://www.api.CodeMonc.com';

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageFileUrl(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (imageFile) {
      uploadImage();
    }
  }, [imageFile]);

  const uploadImage = async () => {
    // service firebase.storage {
    //   match /b/{bucket}/o {
    //     match /{allPaths=**} {
    //       allow read;
    //       allow write: if
    //       request.resource.size < 2 * 1024 * 1024 &&
    //       request.resource.contentType.matches('image/.*')
    //     }
    //   }
    // }
    setImageFileUploading(true);
    setImageFileUploadError(null);
    const storage = getStorage(app);
    const fileName = new Date().getTime() + imageFile.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setImageFileUploadProgress(progress.toFixed(0));
      },
      (error) => {
        setImageFileUploadError(
          'Could not upload image (File must be less than 2MB)'
        );
        setImageFileUploadProgress(null);
        setImageFile(null);
        setImageFileUrl(null);
        setImageFileUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageFileUrl(downloadURL);
          setFormData({ ...formData, profilePicture: downloadURL });
          setImageFileUploading(false);
                 
        });
      }
    );
  };


  const handleChange=(e)=>{
    setFormData({...formData,[e.target.id]:e.target.value})
  }
  const handleSubmit=async(e)=>{
    e.preventDefault()
    setUpdateUserError(null)
    setUpdateUserSucess(null)
    if(Object.keys(formData).length===0){
      setUpdateUserError('No changes made')
      return
    }
    if (imageFileUploading) {
      setUpdateUserError('Please wait for image to upload');
      return;
    }

    try {
      dispatch(updateStart())
      const res= await fetch(`${apiEndpoint}/api/user/update/${currentUser._id}`,{
        method:'PUT',
        headers:{
          'content-type':'application/json'
        },
        body:JSON.stringify(formData)
      })
      const data=await res.json()
    //  console.log(data)

      if(!res.ok){
        dispatch(updateFailure(data.message))
        setUpdateUserError(data.message)
      }else{
        dispatch(updateSuccess(data))
        setUpdateUserSucess('Profile Updated Successfully')
      }
    } catch (error) {
      dispatch(updateFailure(error.message))
      setUpdateUserError(error.message)
    }
  }
  const handleDeleteUser=async()=>{
    setShowModal(false)
    try {
      dispatch(deleteUserStart())
      const res=await fetch(`${apiEndpoint}api/user/delete/${currentUser._id}`,{
        method:'Delete'
      })
      const data=await res.json()
      if(!res.ok){
        dispatch(deleteUserFailure())
      }else{
        dispatch(deleteUserSuccess(data))
      }
    } catch (error) {
      dispatch(deleteUserFailure(error.message))
    }
  }

  const handleSignout=async()=>{
    try {
      const res=await fetch(`${apiEndpoint}/api/user/signout`,{
        method:'POST'
      })
      const data=await res.json()
      if(!res.ok){
        console.log(data.message)
      }else{
        dispatch(signoutSuccess())
      }
      
    } catch (error) {
      console.log(error.message)
    }

  }
  return (
    <>
    <div className='max-w-lg mx-auto p-3 w-full'>
    <h1 className='my-7 text-center font-semibold text-3xl'>Profile</h1>
    <form className='flex flex-col gap-4' onSubmit={handleSubmit}>
    <input
          type='file'
          accept='image/*'
          onChange={handleImageChange}
          ref={filePickerRef}
          hidden
        />
        <div
          className='relative w-32 h-32 self-center cursor-pointer shadow-md overflow-hidden rounded-full'
          onClick={() => filePickerRef.current.click()}
        >
          {imageFileUploadProgress && (
            <CircularProgressbar
              value={imageFileUploadProgress || 0}
              text={`${imageFileUploadProgress}%`}
              strokeWidth={5}
              styles={{
                root: {
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
                path: {
                  stroke: `rgba(62, 152, 199, ${
                    imageFileUploadProgress / 100
                  })`,
                },
              }}
            />
          )}
          <img
            src={imageFileUrl || currentUser.profilePicture}
            alt='user'
            className={`rounded-full w-full h-full object-cover border-8 border-[lightgray] ${
              imageFileUploadProgress &&
              imageFileUploadProgress < 100 &&
              'opacity-60'
            }`}
          />
        </div>
        {imageFileUploadError && (
          <Alert >{imageFileUploadError}</Alert>
        )}
      <TextInput type='text' id='username' placeholder='username' defaultValue={currentUser.username} onChange={handleChange}/>
      <TextInput type='email' id='email' placeholder='email' defaultValue={currentUser.email} onChange={handleChange}/>
      <TextInput type='password' id='password' placeholder='password'  onChange={handleChange}/>
      <Button type='submit' disabled={loading || imageFileUploading}>
        {loading?'Loading...':'Update'}
      </Button>

      {currentUser.isAdmin && (
        <Link to={'/create-post'}>
          <Button type="button " className='w-full'>
            Create a Post
          </Button>
        </Link>
      )}
     
    </form>
    <div className=' flex justify-between'>
      <span className='cursor-pointer' onClick={()=>setShowModal(true)}>Delete Account</span>
      <span className='cursor-pointer' onClick={handleSignout}>Sign-out</span>
    </div>
    {updateUserSucess &&(
      <Alert >
        {updateUserSucess}
      </Alert>
    )}

    {updateUserError && (
        <Alert  className='mt-5 bg-red/80 text-white' >
          {updateUserError}
        </Alert>
      )}

      {error && (
        <Alert  className='mt-5 bg-red/80 text-white'>
          {error}
        </Alert>
      )}

      <Modal className='z-[999999]' show={showModal} onClose={()=>setShowModal(false)} popup size='md'>
        <ModalHeader/>
        <ModalBody>
          <div className='text-center'>
            <HiOutlineExclamationCircle className='h-14 w-14 text-gray-400 dark:text-gray-200 mb-4 mx-auto' />
            <h3 className='mb-5 text-lg text-gray-500 dark:text-gray-400'>
              Are you sure you want to delete your account?
            </h3>
            <div className='flex justify-center gap-4'>
              <Button onClick={handleDeleteUser} className='bg-red ' >
                Yes, I'm sure
              </Button>
              <Button color='gray' onClick={() => setShowModal(false)}>
                No, cancel
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>      
    </>
  )
}
export default DashProfile