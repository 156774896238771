import React from 'react'
import {Link} from 'react-router-dom'
import './Footer.css'
const Footer = () => {
  return (
    <>
        <footer className="new_footer_area bg_color h-[500px] md:h-[350px]">
            <div className="new_footer_top  h-full overflow-hidden">
                <div className=' grid grid-cols-2 md:grid-cols-3 px-10 md:px-20 z-50 gap-2 md:gap-0 absolute text-center '>
                    <div className=' tracking-wider flex flex-col gap-2  items-center'>
                        <h1 className='text-blue font-bold'>Get in Touch</h1>
                        <p className='text-xs px-2 md:px-10 text-gray-500'>Don’t miss any updates of our new schemes and services!</p>
                        <input type='email' placeholder='Email' className='outline-none focus:outline-none hover:border-darkblue bg-transparent border text-sm py-1 px-4 text-gray-700 rounded-md tracking-wide w-32 md:w-52'/>
                        <button className=' border-blue border text-xs  text-white rounded-md  bg-blue hover:bg-transparent hover:text-blue tracking-wide w-28 px-3 py-2 transition-all duration-300'>SUBSCRIBE</button>
                    </div>
                    <div className=''>
                        <h1 className='text-blue font-bold'>Services</h1>
                        <ul className='text-xs leading-7 text-gray-500 flex flex-col font-semibold mx-auto w-20'>
                            <Link to='career' className='hover:bg-blue hover:text-white rounded-sm '>Career</Link>
                            <Link to='courses' className='hover:bg-blue hover:text-white rounded-sm '>Courses</Link>
                            <Link to='contact' className='hover:bg-blue hover:text-white rounded-sm '>Contact</Link>
                            <Link to='blogs' className='hover:bg-blue hover:text-white rounded-sm '>Blogs</Link>
                        </ul>
                    </div>
                    <div className='col-span-2 md:col-span-1'>
                    <h1 className='text-blue font-bold '>About Us</h1>
                    <p className='text-xs tracking-wider leading-5 text-gray-500'>CodeMonc is a leading learning platform dedicated to empowering aspiring software developers with the skills and knowledge needed to excel in the world of web development and cloud computing</p>
                    </div>
                </div>
                <div className="footer_bg " style={{backgroundImage:"url('./media/bg.png')"}}>
                    <div className="footer_bg_one" style={{backgroundImage:"url('./media/bus.gif')"}}></div>
                    <div className="footer_bg_two" style={{backgroundImage:"url('./media/cyclist.gif')"}}></div>
                   
                    <div className=' birds w-28'>
                        <img src='./media/birds1.gif' alt='CodeMonc Coding School' className=''></img>
                    </div>
                    <div className='child w-20 -bottom-2'>
                        <img src='./media/child.gif' alt='CodeMonc Coding School' className=''></img>
                    </div>
                    <div className='mileboard w-20 bottom-12 scale-[2] right-[50%] md:right-[12%]  absolute'>
                    <img src='./media/mileboard.png' alt='CodeMonc Coding School' className='scale-[1.2]'></img>
                    </div>                    
                </div>
            </div>
            <div className='bg-blue text-center font-thin tracking-widest text-sm text-slate-100 p-2'><span>&copy; CodeMonc.com -&nbsp;</span>All rights reserved  </div>
        </footer>
    </>
  )
}

export default Footer
