
import { Route,Routes } from 'react-router'
import { useEffect } from 'react'
import './App.css'
import Home from './pages/home/Home'
import SocialLinks from './components/SocialLinks'
// import ProgressBar from './components/Progressbar'
import Footer from './components/footer/Footer'
import Courses from './pages/course/Courses'
import Contact from './pages/contact/Contact'
import Career from './pages/career/Career'
import Project from './pages/projects/Project'
import Blog from './pages/blogs/Blog'
import ScrollToTopOnPageChange from './components/ScrollToTopOnPageChange'
import SignIn from './pages/signIn/SignIn'
import SignUp from './pages/signUp/SignUp'
import Dashboard from './pages/dashboard/Dashboard'
import Header from './components/navbar/Header'
import PrivateRoute from './components/PrivateRoute'
import OnlyAdminPrivateRoute from './components/OnlyAdminPrivateRoute'
import CreatePost from './pages/blogs/CreatePost'
import UpdatePost from './pages/dashboard/UpdatePosts'
import PostPage from './pages/post/PostPage'
import Search from './components/Search'

// locomotive:--------------
function NotFound() {
  return <img src="./media/img51.jpg " className='w-full md:w-1/3 mx-auto '/>
}

function App() {

  
  return (
    <> 
    <SocialLinks/>
    <ScrollToTopOnPageChange />
   <div className='flex flex-col justify-between  '>
    <Header/>   
    <Routes >
      <Route path="/" element={<><ScrollToTopOnPageChange /><Home /></>}/>
      <Route path="/courses" element={<><ScrollToTopOnPageChange /><Courses /></>} />
      <Route path="/blogs" element={<><ScrollToTopOnPageChange /><Blog /></>} />
      <Route path="/contact" element={<><ScrollToTopOnPageChange /><Contact /></>} />
      <Route path="/project" element={<><ScrollToTopOnPageChange /><Project /></>} />
      <Route path="/career" element={<><ScrollToTopOnPageChange /><Career /></>} />
      <Route path="/sign-in" element={<><ScrollToTopOnPageChange /><SignIn /></>} />
      <Route path="/sign-up" element={<><ScrollToTopOnPageChange /><SignUp /></>} />
      <Route path="/search" element={<><ScrollToTopOnPageChange /><Search /></>} />
      <Route element={
        <PrivateRoute/>}>
        <Route path="/dashboard" element={<Dashboard />}/>
      </Route>
      <Route element={
        <OnlyAdminPrivateRoute/>}>
        <Route path="/create-post" element={<CreatePost />}/>
        <Route path='/update-post/:postId' element={<UpdatePost />} />
      </Route>
      <Route path='/post/:postSlug' element={<PostPage/>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  
    <Footer/> 
    </div> 
    </>
  )
}

export default App
