import { FaPlus, FaMinus } from 'react-icons/fa';
import { FaWhatsapp,FaInstagram,FaLinkedinIn } from "react-icons/fa";
import { BiLogoFacebookCircle, BiLogoFacebookSquare, BiLogoGmail,BiLogoFacebook } from "react-icons/bi";
import './SocialLinks.css';
import { useState } from 'react';
const SocialLinks = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <>   
    <div className={`floating-action-button fixed bottom-20  left-2 flex md:hidden flex-col justify-between items-center z-[9999999]  ${isExpanded ? 'expanded' : ''}`}>
      {isExpanded && (
        <div className="action-buttons">
          {/* Add your additional action buttons here */}
          <button className="action-button bg-[#25D366] text-white"><a href="https://api.whatsapp.com/send?phone=918800763839&text=Hello..."><FaWhatsapp size="24" className='' /></a>
            </button>
          <button className="action-button bg-[#C13584] text-white">
            <a  href="https://www.instagram.com/codemonc_official?igsh=bHhvNnM3d2kwMDE5"><FaInstagram size="25" className='' /></a></button>
          <button className="action-button bg-[#0077b5] text-white">
            <a href="https://www.linkedin.com/company/tech-khojo/" > <FaLinkedinIn size="24" className='' /></a>
           </button>
          <button className="action-button bg-[#f44336] text-white">
            <a href="mailto:codemoncindia@gmail.com"><BiLogoGmail size="24" className=' ' /></a>
            </button>
            <button className="action-button bg-[#0077b5] text-white">
            <a href="https://www.facebook.com/techkhojoofficial?mibextid=LQQJ4d"><BiLogoFacebookCircle size="24" className=' ' /></a>
            </button>
        </div>
      )}
      <button onClick={handleButtonClick} className="main-button bg-darkgreen rounded-full cursor-pointer w-12 h-12  text-white flex justify-center items-center">
        {isExpanded ? <FaMinus /> : <FaPlus />}
      </button>
    </div>
<div id="mySidenav" className="sidenav hidden md:flex flex-col gap-2 fixed z-[9999999] top-[30%]">
  <a href="https://api.whatsapp.com/send?phone=918800763839&text=Hello..." id="Whatsapp" className=' '>
    Whatsapp <FaWhatsapp size="30" className='' />
  </a>
  <a href="https://www.instagram.com/codemonc_official?igsh=bHhvNnM3d2kwMDE5" id="Insta" className=''>
    Instagram <FaInstagram size="30" className='' />
  </a>
  <a href="https://www.linkedin.com/company/tech-khojo/" id="Linkdin" className=''>
    Linkedin <FaLinkedinIn size="28" className='' />
  </a>
  <a href="mailto:codemoncindia@gmail.com" id="Google" className=''>
    Mail <BiLogoGmail size="28" className=' ' />
  </a>
  <a id='fb' href="https://www.facebook.com/techkhojoofficial?mibextid=LQQJ4d">
    Facebook <BiLogoFacebookCircle size="28" className=' ' />
    </a>
</div> 
    </>
  )
}
export default SocialLinks