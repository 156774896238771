import  { useState } from "react";


import Typewriter from "typewriter-effect";
import Cursor from "../../components/cursor/Cursor";
import SocialLinks from "../../components/SocialLinks";
import HomeCourses from "../home/HomeCourse";
const Courses = () => {
  const [isCollapseOpen1, setIsCollapseOpen1] = useState(false);
  const [isCollapseOpen2, setIsCollapseOpen2] = useState(false);

  const toggleCollapse = (section) => {
    if (section === 1) {
      setIsCollapseOpen1(!isCollapseOpen1);
      setIsCollapseOpen2(false); // Close other section
    } else if (section === 2) {
      setIsCollapseOpen2(!isCollapseOpen2);
      setIsCollapseOpen1(false); // Close other section
    }
  };
  return (
    <>
      <Cursor />
      <SocialLinks />
      <div className="w-full h-80 relative maindiv">
        <video
          className="mx-auto w-full h-full object-cover absolute "
          src="./media/video1.mp4"
          autoPlay
          muted
          loop
        ></video>
        <div className="bg-black/30 w-full h-full absolute flex justify-center items-center">
          <h1 className="text-3xl md:text-5xl text-white font-bold tracking-wider text-center">
            <Typewriter
              options={{
                strings: [
                  "Choose the Best For You...",
                  "Become Job Ready...",
                  "Master Your Skills...",
                ],
                autoStart: true,
                loop: true,
              }}
            ></Typewriter>
          </h1>
        </div>
      </div>
      <HomeCourses />
    </>
  );
};

export default Courses;
