import {  Button, Label, Spinner, TextInput } from 'flowbite-react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OAuth from '../../components/OAuth';
const SignUp = () => {
  const [formData, setFormData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate()
  const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://api.CodeMonc.com';


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
  };

  const handleSubmit=async(e)=>{
    e.preventDefault()
    if (!formData.username || !formData.email || !formData.password) {
      return setErrorMessage('Please fill out all fields.');
    }
    try{
      setLoading(true);
      setErrorMessage(null);

      const res=await fetch(`${apiEndpoint}/api/auth/signup`,{
        method:'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      })
      const data=await res.json()
      if (data.success === false) {
        return setErrorMessage(data.message);
      }
      setLoading(false)
      if(res.ok) {
        navigate('/sign-in');
      }
    }
    catch(error){
      setErrorMessage(error.message);
      setLoading(false)
    }
  }
  return (
    <>
    <h1 className='text-center text-3xl tracking-widest font-semibold text-darkgreen p-4'> Sign up</h1>
    <div className='w-52 mx-auto'>
    <form className='flex flex-col gap-4 ' onSubmit={handleSubmit} >
            <div>
              <Label value='Your username' />
              <TextInput
                type='text'
                placeholder='Username'
                id='username'
                onChange={handleChange}
              />
            </div>
            <div>
              <Label value='Your email' />
              <TextInput
                type='email'
                placeholder='name@company.com'
                id='email'
                onChange={handleChange}
              />
            </div>
            <div>
              <Label value='Your password' />
              <TextInput
                type='password'
                placeholder='Password'
                id='password'
                onChange={handleChange}
              />
            </div>
            <Button
              
              type='submit'
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner size='sm' />
                  <span className='pl-3'>Loading...</span>
                </>
              ) : (
                'Sign Up'
              )}
            </Button>
          </form>
          <div className='flex gap-2 text-sm '>
            <span>Have an account?</span>
            <Link to='/sign-in' className='text-blue-500'>
              Sign In
            </Link>
          </div>
          <OAuth/>
          <div className='text-red '>
          {errorMessage && (
            <p className='' >
              {errorMessage}
            </p>
          )}
          </div>
        
    </div>   
    </>
  )
}

export default SignUp
