
const Roadmap=()=>{
    return(
        <>
            
            <h1 className='text-center font-bold text-2xl md:text-4xl tracking-wide  text-darkblue my-5 '>Roadmap to  <span className='text-blue  '>
          <img src='./media/img21.png' className='w-20 md:w-36 z-[9999] absolute  left-[50%] -mt-1 ' alt='CodeMonc Coding School'>
          </img>Career Journey</span>
        </h1>
        <div className="h-60 md:h-2/5 px-10 overflow-hidden ">
      
            <video autoPlay muted loop 
             className="w-full h-full border -mt-16    md:-mt-36 object-contain  "
             src="./media/0313.mp4" poster="./media/roadmapposter.png"></video>
        </div>

        <div className='py-10  border-2' >
      <div className='h-72 w-[90%] mx-auto rounded-md  bg-black bg-contain overflow-hidden  mask-target bg-[100%] md:bg-[70%]' style={{
        backgroundImage: ' url("./media/img23.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
     
       
        }}>
          <div className=' '>
            <div className='tracking-widest p-5  flex gap-5 md:block mx-auto md:float-left '
            style={{
              fontFamily:'Rubik Doodle Shadow'
            }}
            >
              <h1 className='font-bold text-xl md:text-3xl text-white '>A : <span className='text-lg md:text-xl text-blue '>Any</span></h1>
              <h1 className='font-bold text-xl md:text-3xl text-white '>B : <span className='text-lg md:text-xl text-blue '>Body</span></h1>
            
              <h1 className='font-bold text-xl md:text-3xl text-white'>C : <span className='text-lg md:text-xl text-blue '>Can~Code</span></h1>
            </div>
       
             
          </div>
          
      </div>


    </div>
    <div className='grid grid-cols-1 md:grid-cols-3  w-[90%] mx-auto gap-5 mb-10   '>
            <div>
            <iframe title='officeLocation' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1275.7421466622104!2d77.3751224844071!3d28.645852433704622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfaa9092703cf%3A0xf64894021c174540!2sKRISHNA%20APRA%2C%20Niti%20Khand%203%2C%20Manoj%20Vihar%2C%20Indirapuram%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201014!5e0!3m2!1sen!2sin!4v1701768330185!5m2!1sen!2sin" className='w-full h-full' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div>
                <div className='text-slate-200 bg-blue pl-5 py-5 h-full'>
                <h1 className=' text-2xl font-semibold text-white tracking-wider '>Meet Us</h1>
                <ul className='tracking-wider text-sm  leading-6 mt-4'>
                  <li className='font-semibold tracking-widest'><a href='tel:+919811677827'>9811677827</a>&nbsp; <a href='tel:+919311283339'>9311283339</a> &nbsp;<a href='tel:+918800763839'>8800763839</a></li>
                  <li><a href='mailto:Codemoncindia@gmail.com'>Codemoncindia@gmail.com</a></li>
                  <li><h1 className=''>G 17, Krishna Apra D Mall,Shakti Khand 2, Indirapuram</h1></li>
                </ul>
              </div>
            </div>
            <div className='bg-darkblue'>
                <div className=' bg-darkblue  flex flex-col gap-2 pl-4 justify-between py-5'>
                <div className='pr-10'> 
                <h1 className='text-white font-semibold tracking-wide text-xl'>Newsletter</h1>
                <p className='text-slate-200 text-sm tracking-wide mt-2'>Subscribe to our Newsletter so we can spam you with new discounts and offers </p>
                </div>
                <form className=''>
                  <input type='text' placeholder='Enter Your Email Address' className='p-2 text-sm  px-4 outline-none focus:outline-none'/>&nbsp;
                  <button className='bg-blue text-sm tracking-wider text-white p-2 px-4'>SUBMIT</button>
                </form>
              </div>
            </div>
          </div>
        </>
    ) 
}
export default Roadmap