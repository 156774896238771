import React, { useState } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import Typewriter from 'typewriter-effect';
import Cursor from '../../components/cursor/Cursor';
import SocialLinks from '../../components/SocialLinks';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    college: '',
    city: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://www.api.CodeMonc.com';

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    try {
      console.log(apiEndpoint)
      const response = await fetch(`${apiEndpoint}/api/student/inquiries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Fetch Response:', result);

        setIsSubmitted(true);

        setTimeout(() => {
          setIsSubmitted(false);
          setFormData({
            name: '',
            number: '',
            email: '',
            college: '',
            city: '',
          });
        }, 2500);
      } else {
        console.error('Fetch Error:', response.status);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!data.number.trim()) {
      errors.number = 'Number is required';
    } else if (!/^\d+$/.test(data.number)) {
      errors.number = 'Number must contain only digits';
    } else if (data.number.length !== 10) {
      errors.number = 'Number must be 10 digits';
    }

    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format';
    }

    if (!data.college.trim()) {
      errors.college = 'College is required';
    }

    if (!data.city.trim()) {
      errors.city = 'City is required';
    }

    return errors;
  };

  return (
    <>
      <Cursor />
      <SocialLinks />
      <div className='py-10'>
      {isSubmitted ? (
          <div className='text-center fixed  w-full h-screen top-0 z-[9999999] bg-black/50 p-5 flex justify-center items-center'>
            <div className='bg-white w-[70%] md:w-4/12 rounded-md overflow-hidden py-2'>
            <img src='./media/img31.gif' alt='Celebration Ribbon' className='absolute w-[70%]  md:w-4/12 ' />
            <img src='./media/img36.gif' alt='Celebration Ribbon' className=' mt-4 w-96 mx-auto' />
            <button className='text-md md:text-2xl font-bold bg-darkgreen text-white p-2 rounded-sm '>Thank you for your submission!</button>
            </div>
          </div>
           ) : (
          <div className='grid grid-cols-1 md:grid-cols-2 mb-5'>
            <div className='pl-7 md:pl-16 md:p-10 order-2 md:order-1'>
              <h1 className='text-4xl font-semibold text-blue md:mt-6'>
                <Typewriter
                  options={{
                    strings: ['Contact Us...', 'Call Us Now...', 'Visit Our Office...', 'Connect on Social Media'],
                    autoStart: true,
                    loop: true,
                  }}
                ></Typewriter>
              </h1>
              <p className='mt-2 text-slate-600 text-sm md:leading-6'>
                Welcome to our Contact Us page! We're thrilled to connect with you and address any inquiries or feedback
                you might have. Your thoughts are valuable to us, and we appreciate you taking the time to reach out.
              </p>
              <button className='text-sm px-4 py-2 text-white font-semibold mt-2 rounded-md bg-blue flex gap-3 '>
                Call Us <FaPhoneAlt size={20} />
              </button>
            </div>
            <div className='order-1  h-52 md:h-80'>
              <img src='./media/img34.svg' alt='CodeMonc Coding School' className='h-full w-full md:object-cover transform scale-x-[-1] '></img>
            </div>
          </div>
           )}
        

        {!isSubmitted && (
          <div className='grid grid-cols-1 sm:grid-cols-2 shadow-lg w-[80%] sm:w-[70%] md:w-[50%] h-96 mx-auto  mb-16 ' style={{
            background:`linear-gradient(56deg, rgba(237,186,16,1) 0%, rgba(237,186,16,1) 31%, rgba(220,97,9,1) 100%)`
          }}>
            <form onSubmit={handleSubmit} className='flex flex-col gap-3 items-center  py-10 '>
              <h1 className='text-2xl font-bold text-white text-center -mt-5'>Register For Free</h1>
              <p className='text-xs -mt-3 text-slate-200'>submit your details and get free demo class</p>
              <div className=' mt-4'>
                {errors.name && <p className='text-red text-xs'>{errors.name}</p>}

                <input
                  className={`border-b-2  border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.name && 'border-red-500'
                  }`}
                  type='text'
                  placeholder='Your Name'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.number && <p className='text-red text-xs'>{errors.number}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.number && 'border-red-500'
                  }`}
                  type='number'
                  placeholder='Your Number'
                  name='number'
                  value={formData.number}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.email && <p className='text-red text-xs'>{errors.email}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.email && 'border-red-500'
                  }`}
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.college && <p className='text-red text-xs'>{errors.college}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.college && 'border-red-500'
                  }`}
                  type='college'
                  placeholder='Your College'
                  name='college'
                  value={formData.college}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.city && <p className='text-red text-xs'>{errors.city}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.city && 'border-red-500'
                  }`}
                  type='city'
                  placeholder='Your City'
                  name='city'
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div>
                <button
                  className=' bg-white text-darkgreen rounded-sm hover:bg-blue-700 transition
                    w-24 m-auto'
                  type='submit'
                >
                  SUBMIT
                </button>
              </div>
            </form>

            <div className='bg-white overflow-hidden hidden sm:block'>
              <img src='./media/img35.jpg' alt='CodeMonc Coding School' className='w-full h-full object-contain'></img>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Contact;
