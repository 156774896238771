import React, { useState, useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import EnrollmentForm from '../modals/EnrollmentForm';


const Recruiters = () => {
  
  const buttonRef = useRef();
  
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    // speed: 500,
    rows: 3,
    slidesPerRow: 1,
    autoplay: true,          
    autoplaySpeed: 1000,
    arrows:false
    // fade: true,
  };
  return (
    <>
    <div className=''>
    {/* can also add glassmorphism effect in cards */}
    <div className='border-2  bg-blue grid grid-cols-1 md:grid-cols-3 py-5 px-10'>
        <div className=' col-span-2 gap-2  bg-lightgray/50 order-last '>
        <Slider {...settings}>
        <div className='border  h-28 bg-white p-3 shadow-md'><img src='media/itc.png' alt='CodeMonc Coding School' className='w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/accenture.png' alt='CodeMonc Coding School' className='w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/7dot.webp' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/mphasis.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/ltimindtree.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/techm.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/hcl.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/hexaware.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/l&t.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/absoft.webp' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/cognizant.svg' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/deloitte.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/reliancemoney.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/wipro.svg' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/capgeminie.svg' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/tcs2.jpg' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/infobeans.png' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
        <div className=' h-28 bg-white p-3 shadow-md'><img src='media/cisco.svg' alt='CodeMonc Coding School' className='  w-full h-full object-contain '></img></div>
          </Slider>
        </div>
        <div className='p-5 text-center md:text-right  '>
          <h1 className='text-white  leading-[50px] font-bold text-4xl'>Where Our Students Get <span className='text-green ' style={{
            textShadow:'0.5px 0.5px 1px white'
          }}>Hired</span></h1>
          <p className='text-slate-100  text-sm mt-2 recruitertext'>Our dedicated placement program prepares you with the skills to crack a spot in one of the top companies.</p>
          
            <button ref={buttonRef} className='text-white bg-green shadow-lg font-semibold  p-2 px-5 rounded-md tracking-wider text-sm mt-4 ' onClick={openModal}>Enroll Now </button>
            
            
          
        </div>
    </div>
    </div>
    <EnrollmentForm isOpen={isModalOpen} onClose={closeModal}/>
    </>
  )
}
export default Recruiters