import React, { useState } from 'react';
import SocialLinks from '../../components/SocialLinks';
import Typewriter from 'typewriter-effect';
const Career=()=>{
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        position: '',
        city: '',
        experience: '',
      });
      const [isSubmitted, setIsSubmitted] = useState(false);
      const [errors, setErrors] = useState({});    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
   const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://www.api.CodeMonc.com';

    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors).length > 0) {
          return;
        }    
        try {
          const response = await fetch(`${apiEndpoint}/api/employement/career`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            const result = await response.json();
            console.log('Fetch Response:', result);
    
            setIsSubmitted(true);
    
            setTimeout(() => {
              setIsSubmitted(false);
              setFormData({
                name: '',
                number: '',
                email: '',
                position: '',
                city: '',
                experience: '',
              });
            }, 3500);
          } else {
            console.error('Fetch Error:', response.status);
          }
        } catch (error) {
          console.error('Fetch Error:', error);
        }
      };
      const validateForm = (data) => {
        const errors = {};
    
        if (!data.name.trim()) {
          errors.name = 'Name is required';
        }
        if (!data.number.trim()) {
          errors.number = 'Number is required';
        } else if (!/^\d+$/.test(data.number)) {
          errors.number = 'Number must contain only digits';
        } else if (data.number.length !== 10) {
          errors.number = 'Number must be 10 digits';
        }
        if (!data.email.trim()) {
          errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
          errors.email = 'Invalid email format';
        }
        if (!data.city.trim()) {
          errors.city = 'City is required';
        }
        if (!data.position.trim()) {
            errors.position = 'position is required';
          }
    
        return errors;
      };
    
    return(
        <>
        <SocialLinks/>
        <div className="grid grid-cols-1 md:grid-cols-2  ">
            <div className="  w-full h-52 md:h-96  ">
                <img className="h-full w-full object-contain  "
                src="./media/img40.jpeg" alt="CodeMonc Coding School"></img>
            </div>
            <div className='py-2  px-10 md:py-10  '>
            <h1 className='text-2xl md:text-3xl font-semibold text-darkgreen '>
                <Typewriter
                  options={{
                    strings: ['We are Hiring...','Join our Team...'],
                    autoStart: true,
                    loop: true,
                  }}
                ></Typewriter>
              </h1>
              <p className='text-sm font-thick text-black/50 tracking-wide leading-7  mt-2 pr-0 md:pr-32'>CodeMonc is a leading learning platform dedicated to empowering aspiring software developers with the skills and knowledge needed to excel in the world of web development and cloud computing</p>
              <button className='bg-darkgreen p-1 px-2 text-white rounded-sm mt-2'><a href='#form'>Join Now</a></button>
            </div>
        </div>
        {/* for regsitration--------------------- */}
        <div className=' '>
        <div className='py-10'>
      {isSubmitted ? (
          <div className='text-center fixed  w-full h-screen top-0 z-[9999999] bg-black/50 p-5 flex justify-center items-center'>
            <div className='bg-white w-[70%] md:w-4/12 rounded-md overflow-hidden py-2'>
            <img src='./media/img31.gif' alt='Celebration Ribbon' className='absolute w-[70%]  md:w-4/12 ' />
            <img src='./media/img36.gif' alt='Celebration Ribbon' className=' mt-4 w-96 mx-auto' />
            <button className='text-md md:text-2xl font-bold bg-darkgreen text-white p-2 rounded-sm '>Thank you for your submission!</button>
            </div>
          </div>
           ) : (
          <div className='grid grid-cols-1 md:grid-cols-2 mb-5'>
            
          </div>
           )}
        {!isSubmitted && (
          <div className='grid grid-cols-1 sm:grid-cols-2 shadow-lg w-[80%] sm:w-[70%] md:w-[50%] h-96 mx-auto  mb-16 '>
            <form onSubmit={handleSubmit} className='flex flex-col gap-2 items-center bg-darkgreen py-10' id='form'>
              <h1 className='text-2xl font-bold text-white text-center -mt-5'>Join Our Team</h1>
              <p className='text-xs -mt-3 text-slate-200'>submit your details here</p>
              <div className=' mt-2'>
                {errors.name && <p className='text-red text-xs'>{errors.name}</p>}
                <input
                  className={`border-b-2  border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.name && 'border-red-500'
                  }`}
                  type='text'
                  placeholder='Your Name'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.number && <p className='text-red text-xs'>{errors.number}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.number && 'border-red-500'
                  }`}
                  type='number'
                  placeholder='Your Number'
                  name='number'
                  value={formData.number}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.email && <p className='text-red text-xs'>{errors.email}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.email && 'border-red-500'
                  }`}
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
            <div className=" w-32">
                <select
                name="experience"
                id="experience"
                className="form-select  mt-1 block w-full outline-none -ml-8 "
                value={formData.experience}
                onChange={handleChange}
                >
                <option value="Experience" disabled selected >Experience</option>
                <option value="Fresher">Fresher</option>
                <option value="Less than 1 year">Less than 1 year</option>
                <option value="1 year">1 year</option>
                <option value="2 years">2 years</option>
                <option value="2 years">3 years</option>
                <option value="2 years">4+ years</option>
                </select>
            </div>
              </div>
              <div>
                {errors.city && <p className='text-red text-xs'>{errors.city}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.city && 'border-red-500'
                  }`}
                  type='city'
                  placeholder='Your City'
                  name='city'
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.position && <p className='text-red text-xs'>{errors.position}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.position && 'border-red-500'
                  }`}
                  type='text'
                  placeholder='position'
                  name='position'
                  value={formData.position}
                  onChange={handleChange}
                />
              </div>
              

              <div>
                <button
                  className=' bg-white text-darkgreen rounded-sm hover:bg-blue-700 transition
                    w-24 m-auto'
                  type='submit'
                >
                  SUBMIT
                </button>
              </div>
            </form>

            <div className='bg-white overflow-hidden hidden sm:block'>
              <img src='./media/img44.png' alt='CodeMonc Coding School' className='w-full h-full object-contain'></img>
            </div>
          </div>
        )}
      </div>

        </div>
        
        </>
    )

}
export default Career