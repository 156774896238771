import React, { useState, useRef, useEffect } from 'react';
import { MdCancelPresentation } from "react-icons/md";
const RegistrationForm = ({ isOpen, onClose }) => {
  const overlayRef = useRef();
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    college: '',
    city: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (isOpen) {
      const handleOverlayClick = (e) => {
        if (overlayRef.current && e.target === overlayRef.current) {
          onClose();
        }
      };
      document.addEventListener('click', handleOverlayClick);
      return () => {
        document.removeEventListener('click', handleOverlayClick);
      };
    }
  }, [isOpen, onClose]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://www.api.CodeMonc.com';


  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    try {
      const response = await fetch(`${apiEndpoint}/api/student/inquiries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Fetch Response:', result);

        setFormData({
          name: '',
          number: '',
          email: '',
          college: '',
          city: '',
        });

        setIsSubmitted(true);

        setTimeout(() => {
          setIsSubmitted(false);
          onClose();
        }, 2500);
      } else {
        console.error('Fetch Error:', response.status);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
    }
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name.trim()) {
      errors.name = 'Name is required';
    }

    if (!data.number.trim()) {
      errors.number = 'Number is required';
    } else if (!/^\d+$/.test(data.number)) {
      errors.number = 'Number must contain only digits';
    } else if (data.number.length !== 10) {
      errors.number = 'Number must be 10 digits';
    }

    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format';
    }

    if (!data.college.trim()) {
      errors.college = 'College is required';
    }
    if (!data.city.trim()) {
      errors.city = 'City is required';
    }

    return errors;
  };

  if (!isOpen) {
    return null;
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999999]" ref={overlayRef}>
      <div className="bg-white p-6 rounded-md w-72 text-center">
        {isSubmitted ? (
          <div className="text-center relative">
            <img src='./media/img31.gif' alt='Celebration Ribbon' className='absolute w-[70%] -mt-4 left-0 right-0 mx-auto' />
            <img src='./media/img36.gif' alt='Celebration Ribbon' className=' mt-4 w-96 mx-auto' />
            <button className='text-md md:text-2xl font-bold bg-darkgreen text-white p-2 rounded-sm '>Thank you for your submission!</button>
          </div>
        ) : (
          <>
          <span className='float-right absolute ml-24  -mt-4  z-[9999] ' onClick={onClose}><MdCancelPresentation size={30}/></span>
            <h1 className='text-center  text-darkgreen font-bold text-xl pb-2'>Register Now</h1>
            <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
              <div>
                {errors.name && <p className='text-red text-xs'>{errors.name}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${errors.name && 'border-red-500'}`}
                  type='text'
                  placeholder='Your Name'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.number && <p className='text-red text-xs'>{errors.number}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${errors.number && 'border-red-500'}`}
                  type='number'
                  placeholder='Your Number'
                  name='number'
                  value={formData.number}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.email && <p className='text-red text-xs'>{errors.email}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${errors.email && 'border-red-500'}`}
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.college && <p className='text-red text-xs'>{errors.college}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${errors.college && 'border-red-500'}`}
                  type='text'
                  placeholder='College Name'
                  name='college'
                  value={formData.college}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.city && <p className='text-red text-xs'>{errors.city}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-2 rounded-sm focus:outline-none focus:border-blue-500 ${errors.city && 'border-red-500'}`}
                  type='text'
                  placeholder='City Name'
                  name='city'
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <button
                className='bg-darkgreen text-white py-2 px-4 rounded-sm hover:bg-blue-700 transition w-52 m-auto'
                type='submit'
              >
                Submit
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
export default RegistrationForm;