import React, { useState, useRef } from 'react'
import './LandingContact.css'
import Typewriter from "typewriter-effect";
import EnrollmentForm from '../../components/modals/EnrollmentForm';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
const LandingContact = () => {
  const buttonRef = useRef();
  
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div className='py-5'>
        <h1 className='text-center font-bold text-2xl md:text-3xl tracking-widest text-darkblue my-5'>Contact <span className='text-blue  '><img src='./media/img21.png' 
        className='w-20 md:w-36 z-[9999] absolute  left-[50%] -mt-1 ' alt='CodeMonc Coding School'
        ></img>CodeMonc</span></h1>
        <div className='md:px-24 '>
          <div className=' grid grid-cols-1 md:grid-cols-2 overflow-hidden px-10 md:px-0 '>
                <div className=''>
                  <h1 className='text-2xl md:text-3xl font-bold tracking-widest mt-2 text-transparent stroke bg-clip-text'>
                    <Typewriter
                      options={{
                        strings: [ 'Get in Touch...','Call Us Now...','Visit Our Office...'],
                        autoStart: true,
                        loop: true,
                      }}>
                    </Typewriter>
                  </h1>
                  <span>
                  <p className='text-thin text-slate-400 tracking-widest text-sm  md:pr-24 mt-4'>Whether you're looking for more information about our courses, admissions,scholarship programs or just want to say hello, we'd love to hear from you.</p>
                  </span>
                  <div className='flex gap-3 items-center mt-2'>
                  {/* <button className='bg-darkgreen text-sm font-bold tracking-widest text-slate-100 rounded-sm p-2 mt-4 '><a href='tel:+918800763839'>Call Us Now</a></button>  */}
                  <button  onClick={openModal}
                  className='px-2 flex items-center justify-start mt-2 gap-4 rounded-md bg-blue text-white' >
                    <div className='p-2 text-left'>
                      <h1 className='font-semibold tracking-wider text-sm md:text-lg'>Enroll Now</h1>
                      <h2 className='fornt-thin text-xs'>Demo class is free</h2>
                    </div>
                    <div>
                      <MdOutlineKeyboardDoubleArrowRight size={30}/>
                    </div>
                    </button>
                  </div>
                </div>
                <div className='overflow-hidden '>
                <img src='./media/img26.jpg' className='w-96  scale-90 mx-auto md:mx-0 img ' alt='CodeMonc Coding School'></img>
                </div>
          </div> 
         
        </div>
      </div>
      <EnrollmentForm isOpen={isModalOpen} onClose={closeModal}/>
    </>
  )
}
export default LandingContact
