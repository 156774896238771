import {useState,useEffect} from 'react'
import LandingPage from './LandingPage'
import LandingHighlights from './LandingHighlights'
import Recruiters from '../../components/recruiters/Recruiters'
import HomeCourse from './HomeCourse'
import Faq from '../../components/faq/Faq'
import LandingContact from './LandingContact'
import Roadmap from '../../components/Roadmap'
import Cursor from '../../components/cursor/Cursor'
import Banner from '../../components/banner/Banner'
import RegistrationForm from '../../components/modals/RegistrationForm'
import ScholarshipResult from '../../components/ScholarshipResult'
const Home = () => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  // for banner on scroll
  useEffect(()=>{
    setTimeout(()=>{
      setIsBannerOpen(true);
      setHasScrolled(true);
    },4000)
  },[]);
  const closeRegistrationForm = () => {
    setShowRegistrationForm(false);
  };
  const closeBanner = () => {
    setIsBannerOpen(false);
  };
  // Open the registration form after 20 seconds
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowRegistrationForm(true);
    }, 15000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  
  return (
    <>
    <Cursor/>
    <LandingPage/>
    <LandingHighlights/>
    <Recruiters/>
    <HomeCourse/>
    <ScholarshipResult/>
    <Faq/>
    <LandingContact/>
    <Roadmap/>
    {showRegistrationForm && (
          <RegistrationForm isOpen={true} onClose={closeRegistrationForm} />
        )}
        {/* {isBannerOpen && <Banner isOpen={isBannerOpen} onClose={closeBanner} />} */}
    </>
  )
}
export default Home