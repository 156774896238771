// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyDUN-qpfPpjtAjclxvky-o2n7BbhAXo7mA",
  authDomain: "techkhojoofficial-7fe14.firebaseapp.com",
  projectId: "techkhojoofficial-7fe14",
  storageBucket: "techkhojoofficial-7fe14.appspot.com",
  messagingSenderId: "105491201345",
  appId: "1:105491201345:web:ce0195439061e85e6075c0"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);