import {  Button, Label, Spinner, TextInput } from 'flowbite-react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { signInStart,signInSuccess,signInFailure } from '../../redux/user/UserSlice';
import OAuth from '../../components/OAuth';

const SignIn = () => {
  const [formData, setFormData] = useState({});
  const {loading, error : errorMessage}=useSelector(state => state.user)
  const dispatch=useDispatch()
  const navigate=useNavigate()

  const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://www.api.CodeMonc.com';

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
  };

  const handleSubmit=async(e)=>{
    
    e.preventDefault()
    if (!formData.email || !formData.password) {
      return dispatch(signInFailure('Please fill all fields'))
    }
    try{
      dispatch(signInStart())
      const res=await fetch(`${apiEndpoint}/api/auth/signin`,{
        method:'POST',
       
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      })

      const data=await res.json()

      if (data.success === false){
       
        dispatch(signInFailure(data.message))
         console.log(data.message)
      }
      if(res.ok) {
        dispatch(signInSuccess(data))
        navigate('/');
      }
    }
    catch(error){
     
      dispatch(signInFailure(error.message))
    }
  }
  return (
    <>
    <div className='w-52 mx-auto'>
    <form className='flex flex-col gap-4 ' onSubmit={handleSubmit} > 
            <div>
              <Label value='Your email' />
              <TextInput
                type='email'
                placeholder='name@company.com'
                id='email'
                onChange={handleChange}
              />
            </div>
            <div>
              <Label value='Your password' />
              <TextInput
                type='password'
                placeholder='Password'
                id='password'
                onChange={handleChange}
              />
            </div>
            <Button
              type='submit'
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner size='sm' />
                  <span className='pl-3'>Loading...</span>
                </>
              ) : (
                'Sign In'
              )}
            </Button>
          </form>
          <div className='flex gap-2 text-sm '>
            <span>New User?</span>
            <Link to='/sign-up' className='text-blue-500'>
              Sign Up
            </Link>
          </div>
          <OAuth/>
          <div className='text-red '>
          {errorMessage && (
            <p className='' >
              {errorMessage}
            </p>
          )}
          </div>
    </div>   
    </>
  )
}
export default SignIn