import React, { useEffect } from 'react';
import './LandingPageCss.css';
// import Shery from 'sheryjs';
const LandingPage = () => {

  useEffect(() => {
    const cursor1 = document.querySelector('#cursor1');
    const handleMouseMove = (dets) => {
      cursor1.style.left = (dets.x - 90) + 'px';
      cursor1.style.top = (dets.y - 120) + 'px';
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
        <div id="main" className='images w-[100%] h-[300px]   md:h-[300px] mx-auto bg-cover md:bg-contain bg-center' style={{backgroundImage:"url('./media/bg.png')"}}>
        <div id='cursor1' className=' scale-100 h-[100px] w-[100px] md:h-[150px] md:w-[150px] '>
          <div id='innercursor' className='h-[100px] w-[100px] md:h-[150px] md:w-[150px] '>
            {/* <video src='./media/homevideo.mp4'  autoPlay muted loop>
          </video> */}
          <img src='./media/icons.gif' alt='CodeMonc Coding School' className='homeImage' ></img>
          </div>
         </div> 
          <img id='knight' src='./media/knight.gif'  className='w-40 md:w-80 scale-90 absolute left-[5%] md:left-[20%]
           top-32 md:top-0  z-[999] ' alt='CodeMonc Coding School'></img>       
               
        </div>
        <img src='./media/mileboard.png' alt='CodeMonc Coding School' className='absolute scale-[2] top-40 md:top-40 lg:top-40 left-[45%] md:left-[45%] w-32 homeImage' ></img>

        <div className='birds1 w-28 top-[10%] md:top-[20%]'>
            <img src='./media/birds1.gif' alt='CodeMonc Coding School' className=''></img>
         </div>
    </>
  );
};
export default LandingPage;
