import React from 'react'

import { FaGraduationCap } from "react-icons/fa6";
import { FaMedal } from "react-icons/fa";
import { LuComputer } from "react-icons/lu";
import { IoCheckmarkDone } from "react-icons/io5";
import { FaPeopleArrows } from "react-icons/fa";
import { IoMdInfinite } from "react-icons/io";

const LandingHighlights = () => {
    

    const bgGradient={
        'backgroundColor':'#edba10',
    }
  return (
    <>
    <div className='px-2 md:px-20  '>
    <div className='grid grid-cols-1 md:grid-cols-3  '>
        <div className='overflow-hidden hidden md:block '>
            <img src='./media/img27.jpg' alt='CodeMonc Coding School' className='w-80 md:w-96 mx-auto scale-90'></img>  
        </div>
        <div className='grid col-span-2 grid-cols-2 md:grid-cols-2 gap-2  md:p-10 ' > 
            <div className='flex rounded-md  py-2 shadow-md hover:scale-105 transition-all duration-500 hover:shadow-xl   ' style={bgGradient}>
                <div className='w-[30%] flex justify-center items-center'>
                <FaGraduationCap size="50" className="text-green "/>
                </div>
                <div className=' w-[70%]  flex  pl-1 md:pl-3 items-center border-l border-dashed border-slate-300  text-lg md:text-sm lg:text-lg text-white'>
                <p>100% Placement Assistance</p>
                </div>  
            </div>
            <div className='flex border rounded-md py-2 hover:scale-105 transition-all duration-500 hover:shadow-xl  ' style={bgGradient}>
                <div className='w-[30%] flex justify-center items-center '>
                <FaMedal size="40" className="text-green"/>
                </div>
                <div className=' w-[70%] border-l border-dashed border-slate-300 flex pl-1 md:pl-3 items-center text-lg md:text-sm lg:text-lg text-white'>
                <p>23 Years of Industry Expertise</p>
                
                </div>  
            </div>
            <div className='flex border rounded-md py-2 hover:scale-105 transition-all duration-500 hover:shadow-xl ' style={bgGradient}>
                <div className='w-[30%] flex justify-center items-center'>
                <LuComputer size="40" className="text-green"/>
                </div>
                <div className=' w-[70%] border-l border-dashed border-slate-300 flex pl-1 md:pl-3  items-center text-lg md:text-sm lg:text-lg text-white'>
                <p>Paid Internship on Live Projects</p>
                
                </div>  
            </div>
            <div className='flex border rounded-md py-2 hover:scale-105 transition-all duration-500 hover:shadow-xl ' style={bgGradient}>
                <div className='w-[30%] flex justify-center items-center'>
                <IoCheckmarkDone size="40" className="text-green"/>
                </div>
                <div className=' w-[70%] border-l border-dashed border-slate-300 flex pl-1 md:pl-3  items-center text-lg md:text-sm lg:text-lg text-white'>
                <p>Become Job Ready</p>
                
                </div>  
            </div>
            
            <div className='flex border rounded-md py-2 hover:scale-105 transition-all duration-500 hover:shadow-xl ' style={bgGradient}>
                <div className='w-[30%] flex justify-center items-center'>
                <FaPeopleArrows size="40" className="text-green"/>
                </div>
                <div className=' w-[70%] border-l border-dashed border-slate-300 flex pl-1 md:pl-3 items-center text-lg md:text-sm lg:text-lg text-white'>
                <p>1:1 Mentorship</p>
                
                </div>  
            </div>
            <div className='flex border rounded-md py-2 hover:scale-105 transition-all duration-500 hover:shadow-xl ' style={bgGradient}>
                <div className='w-[30%] flex justify-center items-center'>
                <IoMdInfinite size="40" className="text-green"/>
                </div>
                <div className=' w-[70%] border-l border-dashed border-slate-300 flex pl-1 md:pl-3 items-center text-lg md:text-sm lg:text-lg text-white'>
                <p>Life Time Membership Card</p>
                </div>  
            </div>
            

        </div>
    </div>
    </div>
      
    </>
  )
}

export default LandingHighlights
