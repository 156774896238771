import Typewriter from "typewriter-effect";
import { useState } from "react";
const Project=()=>{
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        business: '',
        description: '',
      });
      const [isSubmitted, setIsSubmitted] = useState(false);
      const [errors, setErrors] = useState({});    
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      const apiEndpoint = process.env.REACT_APP_API_BASEURL || 'https://www.api.CodeMonc.com';

    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors).length > 0) {
          return;
        }    
        try {
          const response = await fetch(`${apiEndpoint}/api/business/projects`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            const result = await response.json();
            console.log('Fetch Response:', result);
    
            setIsSubmitted(true);
    
            setTimeout(() => {
              setIsSubmitted(false);
              setFormData({
                name: '',
                number: '',
                email: '',
                business: '',
                description: '',
              });
            }, 3500);
          } else {
            console.error('Fetch Error:', response.status);
          }
        } catch (error) {
          console.error('Fetch Error:', error);
        }
      };
    
      const validateForm = (data) => {
        const errors = {};
    
        if (!data.name.trim()) {
          errors.name = 'Name is required';
        }
    
        if (!data.number.trim()) {
          errors.number = 'Number is required';
        } else if (!/^\d+$/.test(data.number)) {
          errors.number = 'Number must contain only digits';
        } else if (data.number.length !== 10) {
          errors.number = 'Number must be 10 digits';
        }
    
        if (!data.email.trim()) {
          errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
          errors.email = 'Invalid email format';
        }    
        return errors;
      };
    return(
        <>
        <div className=" grid grid-cols-1 md:grid-cols-2">
            <div className="px-5 md:px-16 py-5">
                <h1 className="text-darkgreen text-2xl md:text-3xl font-semibold">
                <Typewriter
                      options={{
                        strings: [ 'Boost Your Business...','Bring it Online...'],
                        autoStart: true,
                        loop: true,
                      }}>
                    </Typewriter>
                </h1>
                
                <p className="font-thin tracking-wider leading-8 mt-3">"Cultivate success in the digital soil. Plant the seeds of innovation, water them with dedication, and watch your business bloom online. In the vast landscape of the internet, let your brand stand tall, a beacon of excellence that attracts success from every corner of the virtual world."</p>
                <button className="bg-darkgreen p-2 text-white rounded-sm px-4 tracking-wide mt-3">
                <a href="tel:+91 8800763839">Call Us           </a>
                </button>
                
            </div>
            <div>
                <img
                className="h-96 w-full  object-cover"
                 src="./media/img47.jpg" alt="CodeMonc Coding School"></img>
            </div>
        </div> 
        <div className="w-full text-center p-2 bg-darkgreen text-white font-thick tracking-widest">
        <p scrollamount="10">Let us Create a Website for Your Business || Boost Your Business  || Get Best Services at very low cost </p>
        </div>
        <div className=' '>
        <div className='py-10'>
      {isSubmitted ? (
          <div className='text-center fixed  w-full h-screen top-0 z-[9999999] bg-black/50 p-5 flex justify-center items-center'>
            <div className='bg-white w-[70%] md:w-4/12 rounded-md overflow-hidden py-2'>
            <img src='./media/img31.gif' alt='Celebration Ribbon' className='absolute w-[70%]  md:w-4/12 ' />
            <img src='./media/img36.gif' alt='Celebration Ribbon' className=' mt-4 w-96 mx-auto' />
            <button className='text-md md:text-2xl font-bold bg-darkgreen text-white p-2 rounded-sm '>Thank you for your submission!</button>
            </div>
          </div>
           ) : (
          <div className='grid grid-cols-1 md:grid-cols-2 mb-5'>
            
          </div>
           )}
        {!isSubmitted && (
          <div className='grid grid-cols-1 sm:grid-cols-2 shadow-lg w-[80%] sm:w-[70%] md:w-[50%] h-96 mx-auto  mb-16 '>
            <form onSubmit={handleSubmit} className='flex flex-col gap-2 items-center bg-darkgreen py-10'>
              <h1 className='text-2xl font-bold text-white text-center -mt-5'>Submit Your Details</h1>
              <p className='text-xs -mt-3 text-slate-200'>we will contact you </p>
              <div className=' mt-2'>
                {errors.name && <p className='text-red text-xs'>{errors.name}</p>}
                <input
                  className={`border-b-2  border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.name && 'border-red-500'
                  }`}
                  type='text'
                  placeholder='Your Name'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.number && <p className='text-red text-xs'>{errors.number}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.number && 'border-red-500'
                  }`}
                  type='number'
                  placeholder='Your Number'
                  name='number'
                  value={formData.number}
                  onChange={handleChange}
                />
              </div>
              <div>
                {errors.email && <p className='text-red text-xs'>{errors.email}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.email && 'border-red-500'
                  }`}
                  type='email'
                  placeholder='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
            
              </div>
              <div>
                {errors.business && <p className='text-red text-xs'>{errors.business}</p>}
                <input
                  className={`border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 ${
                    errors.business && 'border-red-500'
                  }`}
                  type='business'
                  placeholder='Your business'
                  name='business'
                  value={formData.business}
                  onChange={handleChange}
                />
              </div>
              <div>
               
              <textarea
                  className='border-b-2 border-darkgreen p-1 rounded-sm focus:outline-none focus:border-blue-500 -ml-5'
                  type='description'
                  placeholder='Your description (optional)'
                  name='description'
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>

              <div>
                <button
                  className=' bg-white text-darkgreen rounded-sm hover:bg-blue-700 transition
                    w-24 m-auto'
                  type='submit'
                >
                  SUBMIT
                </button>
              </div>
            </form>
            <div className='bg-white overflow-hidden hidden sm:block'>
              <img src='./media/img50.jpg' alt='CodeMonc Coding School' className='w-full h-full object-contain'></img>
            </div>
          </div>
        )}
      </div>
        </div>
        </>
    )
}
export default Project